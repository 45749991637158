var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "d-flex flex-column align-items-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-items-center my-20" },
        [
          _c(
            "v-avatar",
            { attrs: { size: "96" } },
            [
              _c("v-img", {
                attrs: {
                  "lazy-src": require("@/assets/images/profile-circle.png"),
                  src:
                    _vm.Profile.profilePicture != null &&
                    _vm.Profile.profilePicture != ""
                      ? _vm.Profile.profilePicture
                      : require("@/assets/images/profile.jpg"),
                },
              }),
            ],
            1
          ),
          _c("span", { staticClass: "f14 font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.Profile.firstName + " " + _vm.Profile.lastName) +
                " "
            ),
          ]),
          _c("span", { staticClass: "my-1 f13 left-to-right" }, [
            _vm._v(" " + _vm._s(_vm.Profile.mobileNumber) + " "),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "w-100 white rounded-t-xl" },
        [
          _c("div", { staticClass: "sheet-handle w-100 text-center" }, [
            _c("span"),
            _c("span"),
          ]),
          _c(
            "v-list",
            {
              staticClass: "w-100 pt-0 mt-auto",
              attrs: { light: "", shaped: "" },
            },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/appointments",
                        href: "/c/appointments",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#eee8ff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-calendar-alt fa-lg",
                                staticStyle: { color: "#20b1ea" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Appointment")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/exams",
                        href: "/c/exams",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#f0f7ff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-book-open fa-lg",
                                staticStyle: { color: "#20b1ea" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Exams")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/exercises",
                        href: "/c/exercises",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#fff3e1",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-clipboard-list fa-lg",
                                staticStyle: { color: "#f8b758" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Exercises")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/profile/wallet",
                        href: "/c/profile/wallet",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#e4ecff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "tone",
                                  name: "wallet",
                                  color: "#91b2ff",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Wallet")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/profile/questions",
                        href: "/c/profile/questions",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#feebdd",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-question-circle fa-lg",
                                staticStyle: { color: "#faa365" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Questions and answers")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/moods",
                        href: "/c/moods",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#ecfff4",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-chart-line fa-lg",
                                staticStyle: { color: "#25ac71" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Mood status")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/profile/favorites",
                        href: "/c/profile/favorites",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#fff9e4",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "bookmark",
                                  color: "#F9A825",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Favorites")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/profile/setting",
                        href: "/c/profile/setting",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#fbe9e7",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "setting",
                                  color: "#ff5722",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Setting")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        to: "/c/profile/edit",
                        href: "/c/profile/edit",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#e8ffff",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "profile",
                                  color: "#00d0d4",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Edit profile")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "arrow-left2",
                              color: "#757575",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        href: "https://mindology.io/about-us",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#E8EAF6",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "infocircle",
                                  color: "#5C6BC0",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("About us")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        link: "",
                        ripple: "",
                        href: "https://mindology.io/contact-us",
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center rounded-lg",
                              attrs: {
                                color: "#F1F8E9",
                                elevation: "0",
                                height: "36",
                                width: "36",
                              },
                            },
                            [
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "calling",
                                  color: "#689F38",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "font-weight-bold f15" },
                            [_vm._v(_vm._s(_vm.$t("Contact us")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-2",
                          attrs: {
                            color: "accent",
                            block: "",
                            rounded: "",
                            large: "",
                          },
                          on: { click: _vm.logout },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Log out")) + " "),
                          _c("iconly", {
                            staticClass: "ms-2",
                            attrs: {
                              type: "bulk",
                              name: "logout",
                              color: "#FFFFFF",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-center d-block w-100 fw-bold f14 grey--text text--darken-3",
                    },
                    [_vm._v("v" + _vm._s(_vm.version))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }